<template>
  <!-- <div
    :style="{ 'margin-top': '-40px', transform: 'translateY(' + top + 'px)' }"
  >
    <div
      class="loading-box"
      v-if="!isRefresh"
      :style="{
        color: '#969799',
        'font-size': '14px',
        margin: '10px 0',
      }"
    >
      <van-icon
        name="down"
        :style="{
          'margin-right': '10px',
          transform: flag ? 'rotate(180deg)' : '',
        }"
      />
      <span>{{ loadingText }}</span>
    </div>
    <van-loading v-else style="padding: 15px 0">{{ loadingText }}</van-loading>
    <div
      class="data-statis-box"
      style="width: 100%; height: 100vh"
      @touchstart="touchStart($event)"
      @touchmove="touchMove($event)"
      @touchend="touchEnd($event, searchUserStatis, resetQueryParams)"
    > -->
  <div class="data-statis-box" style="width: 100%; height: 100vh">
    <div class="date-box">
      <span class="select-data" @click="showBottom = true">{{
        dateValue
      }}</span>
      <span class="select-data" @click="showBottom2 = true">{{
        dateValue2
      }}</span>
    </div>
    <div style="padding: 10px 0;border-bottom: 1px solid #ccc;">
      <div  class="item d-flex align-center justify-around">
        <div class="d-flex justify-center align-center flex-column "
          style="width:140px;"
        >
          <span>校区</span>
          <van-popover
            v-model:show="showCampusPopover"
            placement="bottom-start"
          >
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="width: 240px; padding: 10px; box-sizing: border-box"
            >
              <van-grid-item
                v-for="item in campusList"
                :key="item"
                @click="selectCampusFun(item)"
              >
                {{ item.campus_name }}
              </van-grid-item>
            </van-grid>
            <template #reference>
              <van-button type="default" size="mini">{{
                selectCampus == "请选择" ? selectCampus : selectCampus
              }}</van-button>
            </template>
          </van-popover>
        </div>
        <div class="right-box">
          <div>{{ campus_name_user }}</div>
        </div>
      </div>
    </div>
    <div class="statis-body-header d-flex align-center justify-around">
      <div class="left " style="width:140px;">
        <div>员工</div>
        <div style="margin-top: 14px">
          <van-popover v-model:show="showUserPopover" placement="bottom-start">
            <van-grid
              square
              clickable
              :border="false"
              column-num="3"
              style="
                  width: 240px;
                  padding: 10px;
                  box-sizing: border-box;
                  max-height: 200px;
                  overflow-y: auto;
                "
            >
              <van-empty
                image="search"
                v-if="memberList.length == 0"
                description="没有该员工"
              />
              <van-grid-item
                v-for="item in memberList"
                :key="item"
                :text="item.name"
                @click="showUser(item)"
              />
            </van-grid>
            <template #reference>
              <van-field
                style="padding: 0"
                v-model="searchUserValue"
                @update:model-value="searchUser"
                placeholder="选择员工"
              />
            </template>
          </van-popover>
        </div>
      </div>
      <div class="scroll-right">
        <div :style="{width:dongShopNum*150+'px'}">
          <div class="scroll-right-item" v-for="item in yuanShopData" :key="item">
            <p>{{ item.name }}</p>
            <p>{{ item.value }}桶</p>
          </div>
        </div>
      </div>
    </div>
    <div class="statis-body-content">
      <div class="building-layer-box">
        
        <div class="item d-flex align-center justify-around">
          <div class="d-flex justify-center align-center flex-column" style="width:140px;">
            <span class="span-title">幢</span>
            <van-popover
              v-model:show="showBuildingPopover"
              placement="bottom-start"
              @open="openBuildingList"
            >
              <van-grid
                square
                clickable
                :border="false"
                column-num="3"
                style="width: 240px; padding: 10px; box-sizing: border-box"
              >
                <van-grid-item
                  v-for="item in buildingList"
                  :key="item"
                  @click="showBuilding(item)"
                >
                  {{ item.name }}
                </van-grid-item>
              </van-grid>
              <template #reference>
                <van-button type="default" size="mini">{{
                  showBuildingNum == "请选择" ? "请选择" : showBuildingNum
                }}</van-button>
              </template>
            </van-popover>
          </div>
          <div class="scroll-right">
            <div :style="{width:dongShopNum*150+'px'}">
              <div class="scroll-right-item" v-for="item in dongShopData" :key="item">
                <p>{{ item.name }}</p>
                <p>{{ item.value }}桶</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item d-flex align-center justify-around">
          <div class="d-flex justify-center align-center flex-column" style="width:140px;">
            <span class="span-title">层</span>
            <van-popover
              v-model:show="showLayerPopover"
              placement="bottom-start"
              @open="openLayer"
            >
              <van-grid
                square
                clickable
                :border="false"
                column-num="3"
                style="width: 240px; padding: 10px; box-sizing: border-box"
              >
                <van-grid-item
                  v-for="item in FloorList"
                  :key="item"
                  :text="item.name"
                  @click="showLayer(item)"
                >
                {{ item.name }}
                </van-grid-item>
              </van-grid>
              <template #reference>
                <van-button type="default" size="mini">{{
                  showLayerNum == "请选择" ? "请选择" : showLayerNum > 0 ? showLayerNum + "层" :showLayerNum
                }}</van-button>
              </template>
            </van-popover>
          </div>
          <div class="scroll-right">
            <div :style="{width:dongShopNum*150+'px'}">
              <div class="scroll-right-item" v-for="item in cengShopData" :key="item">
                <p>{{ item.name }}</p>
                <p>{{ item.value }}桶</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="showBottom"
      position="bottom"
      :style="{ height: '47%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate"
        @cancel="showBottom = false"
      />
    </van-popup>
    <van-popup
      v-model:show="showBottom2"
      position="bottom"
      :style="{ height: '47%' }"
    >
      <van-datetime-picker
        v-model="currentDate2"
        type="date"
        title="选择日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate2"
        @cancel="showBottom2 = false"
      />
    </van-popup>
  </div>
  <!-- </div> -->
</template>

<script>
import { ref, reactive, computed } from "vue";
import refresh from "../../../state/refresh-data";
import {
  Button,
  Grid,
  GridItem,
  Popover,
  Toast,
  Field,
  Empty,
  DatetimePicker,
  Popup,
  // Loading,
  // Icon,
} from "vant";
import api from "../../../api/statistics";
import bfr from "../../../api/room";
import httpAdminUser from "../../../api/adminUser";
import httpCampus from "../../../api/getCampus";
import { setBuildText,setCengText,setRoomText } from '../../../lib/setBuild'


export default {
  name: "Statis",
  components: {
    "van-button": Button,
    "van-grid": Grid,
    "van-grid-item": GridItem,
    "van-popover": Popover,
    "van-field": Field,
    "van-empty": Empty,
    "van-popup": Popup,
    "van-datetime-picker": DatetimePicker,
    // "van-loading": Loading,
    // "van-icon": Icon,
  },
  setup() {
    // 搜索参数
    let queryParams = reactive({
      start_time: "",
      end_time: "",
      user_id: "",
      building_no: "",
      floor_no: "",
      campus_id:""
    });
    const show = ref(false);
    // 搜索框的值
    const value = ref("");

    // 搜索员工
    const yuanShopData = ref([]);//员工品牌数据
    const dongShopData = ref([]);//栋数品牌数据
    const dongShopNum = ref(0);
    const cengShopData = ref([]);//层数品牌数据
    const searchUserStatis = async () => {
      try {
        const res = await api.getUserStatistics(queryParams);
        if (res.code == 200) {
          isRefresh.value = false;
          loadingText.value = "下拉刷新";
          top.value = 0;
          flag.value = false;
          //获取品牌列表
          dongShopData.value = []
          cengShopData.value = []
          yuanShopData.value = []
          res.data.brandList.forEach((item)=>{
            item.text = item.brand_name
            item.value = item.brand_id
            dongShopData.value.push({
              name:item.brand_name,
              value:item.buildingBrandNum
            })
            cengShopData.value.push({
              name:item.brand_name,
              value:item.floorBrandNum
            })
            yuanShopData.value.push({
              name:item.brand_name,
              value:item.memberBrandNum
            })
          })
          dongShopNum.value = res.data.brandList.length
        }
      } catch (error) {
        Toast("搜索出错");
      }
    };

    //校区
    const showCampusPopover = ref(false)
    const campusList = ref([])
    const campus_id =ref('')
    const selectCampus = ref('请选择')
    const campus_name_user = ref('')
    const getCampusListFun = async()=>{
      const res = await httpCampus.getCampusList();
      if(res.code == 200){
        campusList.value = res.data;
      }
    }
    getCampusListFun()
    // 选择 校区
    const selectCampusFun = (item) => {
      selectCampus.value = item.campus_name;
      queryParams.campus_id = item.campus_id;
      campus_name_user.value =item.campus_name
      showCampusPopover.value = false;
      getBuildingList();
    };

    // 栋数 气泡弹窗、选择栋数、栋统计
    const showBuildingPopover = ref(false);
    const showBuildingNum = ref('请选择');
    const showBuilding = async (item) => {
      queryParams.building_no = item == "全部" ? "" : item.id;
      showBuildingNum.value = item == "全部" ? 0 : item.name;
      showBuildingPopover.value = false;
      searchUserStatis();
      getFloorList();
    };
    const openBuildingList = () =>{
      if (queryParams.campus_id == "") {
        Toast("请先选择校区");
        showBuildingPopover.value = false;
      }
    }

    // 层数 气泡弹窗、选择层数
    const showLayerPopover = ref(false);
    const showLayerNum = ref('请选择');
    const showLayer = async (item) => {
      queryParams.floor_no = item == "全部" ? "" : item.id;
      showLayerNum.value = item == "全部" ? 0 : item.name;
      showLayerPopover.value = false;
      searchUserStatis();
    };
    const openLayer = () => {
      if (showBuildingNum.value == "请选择") {
        console.log(132132132)
        Toast("请先选择幢数");
        showLayerPopover.value = false;
      }
    };

    // 选择员工
    const searchUserValue = ref("");
    const showUserPopover = ref(false);
    const showUserName = ref("");
    const showUser = async (item) => {
      queryParams.user_id = item.name == "全部" ? "" : item.id;
      showUserName.value = item.name == "全部" ? "" : item.name;
      showUserPopover.value = false;
      searchUserValue.value = item.name == "全部" ? "" : item.name;
      searchUserStatis();
    };

    const memberList = ref([]);
    const oldMemberList = ref([]);
    const searchUser = (value) => {
      if (value.length == 0) memberList.value = oldMemberList.value;
      const arr = [];
      memberList.value.forEach((el) => {
        if (el.name.indexOf(value) != -1) {
          arr.push(el);
        }
      });
      memberList.value = arr;
    };

    // 获取员工
    const getMemberList = async () => {
      const res = await httpAdminUser.queryAllUser();
      // console.log(res);
      if (res.code == 200) {
        memberList.value = [
          {
            name: "全部",
          },
          ...res.data,
        ];
        oldMemberList.value = [
          {
            name: "全部",
          },
          ...res.data,
        ];
      }
    };
    getMemberList();
    // 获取所有栋数
    const buildingList = ref([]);
    const getBuildingList = async () => {
      console.log(queryParams.campus_id)
      try {
        const res = await bfr.getAllBuilding({
          campus_id:queryParams.campus_id
        });
        if (res.code == 200) {
          // buildingList.value = ["全部", ...res.data];
          buildingList.value = setBuildText(selectCampus.value,res.data);
          buildingList.value.unshift({
            name:'全部',
            value:''
          })
        }
      } catch (error) {
        Toast("获取栋数出错");
      }
    };
   
    // 获取所有层数
    const FloorList = ref([]);
    const getFloorList = async () => {
      try {
        const res = await bfr.getAllFloor({
          building_no: queryParams.building_no,
          campus_id:queryParams.campus_id
        });
        if (res.code == 200) {
          FloorList.value = setCengText(selectCampus.value,queryParams.building_no,res.data);
          FloorList.value.unshift({
            name:'全部',
            value:''
          })
        }
      } catch (error) {
        Toast("获取层数出错");
      }
    };

    // 开始日期
    const currentDate = ref(new Date());
    const showBottom = ref(false);
    const dateValue = ref("开始日期");
    const confirmDate = async (val) => {
      const y = val.getFullYear();
      const m = val.getMonth() + 1;
      const d = val.getDate();
      const formatDate = y + "-" + m + "-" + d;
      queryParams.start_time = formatDate;
      dateValue.value = formatDate;
      searchUserStatis();
      showBottom.value = false;
    };

    // 结束日期
    const currentDate2 = ref(new Date());
    const showBottom2 = ref(false);
    const dateValue2 = ref("结束日期");
    const confirmDate2 = async (val) => {
      // console.log(val);
      const y = val.getFullYear();
      const m = val.getMonth() + 1;
      const d = val.getDate();
      const formatDate = y + "-" + m + "-" + d;
      queryParams.end_time = formatDate;
      dateValue2.value = formatDate;
      searchUserStatis();
      showBottom2.value = false;
    };

    const resetQueryParams = () => {
      queryParams.start_time = "";
      queryParams.end_time = "";
      queryParams.user_id = "";
      queryParams.building_no = "";
      queryParams.floor_no = "";
      queryParams.campus_id = ""
      showBuildingNum.value = '请选择';
      showLayerNum.value = '请选择';
      searchUserValue.value = "";
      dateValue.value = "开始日期";
      dateValue2.value = "结束日期";
    };

    // 刷新
    const {
      flag,
      isRefresh,
      loadingText,
      top,
      touchStart,
      touchMove,
      touchEnd,
    } = refresh();

    return {
      // protyper
      show,
      value,
      showLayerPopover,
      showBuildingPopover,
      buildingList,
      showBuildingNum,
      showLayerNum,
      FloorList,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2100, 12, 31),
      showUserPopover,
      showUserName,
      searchUserValue,
      memberList,
      currentDate,
      showBottom,
      dateValue,
      currentDate2,
      showBottom2,
      dateValue2,
      flag,
      isRefresh,
      loadingText,
      top,
      showCampusPopover,
      campusList,
      campus_id,
      selectCampus,
      campus_name_user,
      dongShopData,
      cengShopData,
      yuanShopData,
      dongShopNum,

      // method
      showBuilding,
      showLayer,
      openLayer,
      showUser,
      searchUser,
      resetQueryParams,
      searchUserStatis,
      confirmDate,
      confirmDate2,
      touchStart,
      touchMove,
      touchEnd,
      selectCampusFun,
      openBuildingList,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}

.date {
  width: 132px;
}

.statis-header {
  border-bottom: 2px solid #ccc;
  padding: 0 20px;
  // margin-bottom: 40px;
}

.statis-body-header {
  font-size: 30px;
}

.building-layer-box {
  border-bottom: 2px solid #ccc;
  margin-bottom: 40px;

  .item {
    border-top: 2px solid #ccc;
    padding: 20px 0;
    font-size: 30px;
  }
}

.span-title {
  margin-bottom: 5px;
}

.date-box {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  justify-content: space-around;
}

.select-data {
  background-color: white;
  min-width: 100px;
  height: 100px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  max-width: 200px;
}

::v-deep .van-grid-item__content {
  width: 140px;
  height: 52px;
  border: 2px solid #ccc;
  padding: 0;
  margin-left: 10px;
}

::v-deep .van-grid {
  height: 240px;
  overflow-y: auto;
}

::v-deep .van-grid-item {
  padding-top: 80px !important;
}

::v-deep .van-empty {
  margin-left: 40px;
}

::v-deep .van-button {
  font-size: 30px;
}

::v-deep .van-empty__image {
  width: 200px !important;
  height: 200px !important;
}

::v-deep .van-field__control {
  text-align: center;
}
.right-box{
  width: calc(100% - 200px);
}
.scroll-right{
  width: calc(100% - 200px);
  padding: 20px 0;
  min-height: 140px;
  overflow: hidden;
  overflow-x: scroll;
}
.scroll-right-item{
  width: 140px;
  height: 100%;
  float: left;
  display: block;
  margin-right: 60px;
}
.scroll-right-item p {
  margin:0;
  margin-bottom: 20px;
}
.scroll-right-item p:nth-of-type(2) {
  margin:0;
}
</style>
